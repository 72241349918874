.tests__container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.tests__select {
  display: flex;
  gap: 24px;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.tests__type {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 31px 47px;
  box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;
  max-width: 302px;
  min-height: 302px;

  div {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  svg {
    color: $main-color;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    color: $main-color;
    font-weight: 400;
  }

  p {
    color: #424242;
    font-size: 16px;
    line-height: 20px;
  }

  a {
    margin-top: auto;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  span {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #fadada;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tests__type.disabled {
  span {
    background-color: #f2f2f7;
  }

  svg {
    color: #9e9e9e;
  }

  h2 {
    color: #9e9e9e;
  }

  p {
    color: #9e9e9e;
  }

  a {
    background-color: #f2f2f7;
    color: #9b99a1;
    cursor: default;
  }
}

@media (max-width: $tablet-width) {
  .tests__container {
    gap: 40px;
  }

  .tests__select {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: stretch;
    width: 100%;
  }

  .tests__type {
    gap: 24px;
    padding: 31px 47px;
    box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
    border-radius: 16px;
    max-width: 100%;
    min-height: 260px;
  }
}

@media (max-width: $mobile-width) {
  .tests__container {
    gap: 24px;
  }

  .tests__select {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .tests__type {
    gap: 24px;
    padding: 16px;
    min-height: 210px;
  }
}
