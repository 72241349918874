.title {
  display: flex;
  gap: 24px;
  align-items: center;

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $main-color;
    color: $color-default-white;
    display: flex;
    justify-content: center;
    align-items: center;

    @include hover-focus {
      background-color: #f2f2f7;
      color: $main-color;
    }

    &:active {
      transform: $trans;
    }
  }

  svg {
    margin-left: -1px;
  }

  h1 {
    font-size: 36px;
    line-height: 44px;
    color: $text-color;
    font-weight: 700;
  }
}

@media (max-width: $tablet-width) {
  .title {
    a {
      min-width: 35px;
      width: 35px;
      height: 35px;
    }

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

@media (max-width: $mobile-width) {
  .title {
    align-items: center;

    svg {
      width: 6px;
      height: 10px;
    }

    h1 {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .photo__skip {
    font-size: 14px;
  }
}
