.agree {
  flex-grow: 1;
}

.agree__container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
  }

  a {
    color: $main-color;

    @include hover-focus {
      padding-bottom: 1px;
      border-bottom: 1px solid $main-color;
    }

    &:active {
      transform: $trans;
    }
  }
}