.card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 401px;
  width: 100%;
  min-height: 494px;

  h3 {
    font-size: 18px;
    line-height: 24px;
    color: $text-color;
    font-weight: 900;
  }
}

.card__name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $text-color-grey;
  max-height: 60px;
  min-height: 60px;
  overflow-y: auto;
}

.card__prod {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);

  img {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    object-fit: contain;
  }
}

.card__prod-cont {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.card__prod-text {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.card__prod-label {
  padding: 6px 16px;
  border: 1px solid #424242;
  font-size: 14px;
  line-height: 18px;
  border-radius: 8px;
  width: fit-content;
  min-height: 32px;
}

.card__prod-name {
  font-size: 16px;
  line-height: 20px;
  color: $text-color;
  font-weight: 500;
  min-height: 60px;
}

.card__prod-price {
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  color: $main-color;
}

.card__details {
  color: $main-color;
  cursor: pointer;
  font-size: 14px;
  border: none;
  border-bottom: 1px dotted $main-color;
  background-color: transparent;
  width: fit-content;
  padding: 5px;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $text-color;
    border-color: $text-color;
  }

  &:not(:disabled):active {
    transform: scale(90%);
  }

  &:disabled {
    opacity: 90%;
    cursor: crosshair;
  }
}

.card__alter,
.card__add {
  min-height: 48px;
  padding: 10px 30px;
  width: 100%;
}

.card__counter-wrapper {
  background-color: #fff;
  border: 1px solid #dde1e6;
  border-radius: 8px;
  display: none;
  height: 36px;
  width: 100%;
}

.card__decr,
.card__incr {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 30px;
  cursor: pointer;

  &:hover ,
  &:focus {
    border-radius: 6px;
    background-color: $main-color;
  }

  &:hover svg,
  &:focus svg {
    color: $color-default-white;
  }

  &:active {
    transform: scale(90%);
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.card__input {
  border: none;
  height: 100%;
  outline: none;
  padding: 0 4px;
  text-align: center;
  width: 100%;
}

@media (max-width: $tablet-width) {
  .card {
    max-width: 49%;
    min-height: 350px;
    gap: 0;
  
    h3 {
      min-height: 50px;
    }
  }

  .card__prod-cont {
    gap: 18px;
  }

  .card__prod-text {
    gap: 18px;
  }
}

@media (max-width: $mobile-width) {
  .card {
    max-width: 100%;
    min-height: 310px;
    gap: 10px;
  
    h3 {
      font-size: 16px;
      line-height: 20px;
      min-height: auto;
    }
  }
  
  .card__prod {
    gap: 16px;
    padding: 16px;
  }

  .card__prod-cont {
    gap: 5px;
    flex-direction: row;
    align-items: center;

    img {
      min-width: 90px;
      max-width: 90px;
      height: 90px;
    }
  }

  .card__prod-text {
    gap: 8px;
    flex-direction: column-reverse;
    width: 100%;
  }
  
  .card__prod-label {
    display: none;
  }
  
  .card__prod-name {
    font-size: 14px;
    line-height: 16px;
    min-height: 52px;
  }
  
  .card__prod-price {
    font-size: 18px;
    line-height: 24px;
  }
  
  .card__details {
    margin: 0 auto;
  }
  
  .card__alter,
  .card__add {
    min-height: 42px;
    padding: 10px 30px;
    width: 100%;
  }
  
  .card__counter-wrapper {
    background-color: #fff;
    border: 1px solid #dde1e6;
    border-radius: 8px;
    display: none;
    height: 36px;
    width: 100%;
  }
  
  .card__decr,
  .card__incr {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 30px;
  
    svg {
      height: 24px;
      width: 24px;
    }
  }
  
  .card__input {
    border: none;
    height: 100%;
    outline: none;
    padding: 0 4px;
    text-align: center;
    width: 100%;
  }
}