.prod__details {
  height: 100%;
  max-height: 880px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  touch-action: auto;
}

.prod__details-element {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    color: $text-color;

  }
}

.prod__details-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  li {
    color: $text-color;
  }
}