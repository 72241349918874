.politicy {
  font-family: serif;
  font-weight: 300;
  &__list-main,
  &__list-sub,
  &__list-subsub,
  &__list-subsubsub {
    counter-reset: section;

    > li {
      counter-increment: section;

      &::before {
        content: counters(section, '.') ' ';
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
}

.politicy__container {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 30px;
  }
}

.politicy__list-main {
  display: flex;
  flex-direction: column;

  a {
    color: $main-color;

    @include hover-focus {
      padding-bottom: 1px;
      border-bottom: 1px solid $main-color;
    }

    &:active {
      transform: $trans;
    }
  }
}

.politicy__list-main > li {
  margin-bottom: 20px;
}

.politicy__list-sub {
  padding-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > li {
    &::before {
      content: counters(section, '.') ' ';
    }
  }
}

.politicy__list-subsub {
  padding-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > li {
    &::before {
      content: counters(section, '.') ' ';
    }
  }
}

.politicy__list-subsubsub {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > li {
    &::before {
      content: counters(section, '.') ' ';
    }
  }

  p {
    margin-top: 10px;
  }
}
