@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  @content;
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

// Desktop first

@mixin vp-1199 {
  @media (max-width: ($tablet-width - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($mobile-width - 1px)) {
    @content;
  }
}
