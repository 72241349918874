.order__container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  position: relative;
  flex-wrap: wrap;
}

.order__main {
  max-width: 62%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order__title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;

  h1 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }
}

.order__shop {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;

  span {
    color: $text-color-grey;
  }

  p {
    color: $text-color;
  }
}

.order__list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: $text-color;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  li {
    padding: 22px 24px;
    border-radius: 16px;
    border: 1px solid #dde1e6;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    box-shadow: 0 0 6px 1px hsla(0,0%,64%,.55);
  }
}

.order__data {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 430px;
  width: 100%;

  img {
    min-width: 94px;
    height: 94px;
    object-fit: contain;
  }

  p {
    color: $text-color;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 4);
    transition: max-height 0.3s, -webkit-line-clamp 0.3s;

    &.is-opened {
      max-height: none;
      -webkit-line-clamp: unset;
      white-space: normal;
    }
  }
}

.order__price {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 105px;
  width: 100%;

  span {
    font-size: 22px;
    line-height: 27px;
    color: $text-color;
    font-weight: 500;
  }

  p {
    display: flex;
    width: 100%;
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid #dde1e6;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: $text-color-grey;
    justify-content: center;
  }
}

.order__total {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px;
  box-shadow: 0px 0px 6px 1px rgb(208, 20, 129, 0.55);
  border-radius: 16px;
  color: $main-color;
  margin-top: 40px;

  dl {
    display: flex;
    margin: 0;
    justify-content: space-between;
    gap: 40px;
  }

  dt {
    color: $text-color-grey;
  }

  dd {
    margin: 0;
    color: $text-color-grey;
  }

  dl:last-child {
    dt,
    dd {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $text-color;
    }
  }
}

.order__side {
  max-width: 410px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 700px;
}

.order__qr {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  background-color: rgba(246, 208, 230, 0.4);
  padding: 32px;
  border-radius: 16px;
  width: 100%;

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: $main-color;
    text-align: center;
  }

  p {
    color: $text-color;
    font-weight: 500;
  }
}

.order__links {
  display: flex;
  flex-direction: column;
  gap: 15px;

  a {
    width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .order__container {
    display: flex;
    flex-direction: column;
  }

  .order__main {
    gap: 20px;
    order: 3;
    max-width: 100%;
  }

  .order__side {
    max-width: 100%;
    order: 2;
  }
}

@media (max-width: $mobile-width) {
  .order__container {
    gap: 16px;
  }
  
  .order__main {
    width: 100%;
    gap: 24px;
  }
  
  .order__title {
    h1 {
      font-size: 24px;
      line-height: 30px;
    }
  }
  
  .order__shop {
    gap: 0;
  }
  
  .order__list {
    h3 {
      font-size: 18px;
      line-height: 22px;
    }

    ul {
      gap: 10px;
    }
  
    li {
      padding: 10px 12px;
      flex-direction: column;
      gap: 10px;
    }
  }
  
  .order__data {
    max-width: 100%;
  
    p {
      -webkit-line-clamp: 2;
      max-height: calc(1.2em * 2);
    }
  }
  
  .order__price {
    flex-direction: row;
    gap: 18px;
    max-width: 100%;
    justify-content: space-between;
  
    span {
      font-size: 18px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: fit-content;
    }
  
    p {
      padding: 5px 10px;
      font-size: 16px;
      max-width: 60%;
    }
  }
  
  .order__qr {
    max-width: 100%;
  }

  .order__links {
    display: none;
  }
}