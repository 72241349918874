.form__field {
  display: flex;
  flex-direction: column;
  gap: 24px;

  input {
    border: 1px solid #dde1e6;
    background-color: #f2f2f7;
    border-radius: 10px;
    min-height: 66px;
    padding: 20px 24px;
    width: 100%;
    font-size: 18px;
    line-height: 26px;

    &::placeholder {
      font-size: 18px;
      line-height: 26px;
      color: $text-color-grey;
    }

    @include hover-focus {
      border-color: $main-color;
    }

    &:disabled {
      opacity: 10%;
      cursor: crosshair;
    }
  }
}

.form__error {
  display: flex;

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $color-error;
  }
}
