.profile__tests-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.profile__tests-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.profile__test {
  width: 100%;
  min-width: 250px;
  max-width: 100%;
  min-height: 250px;

  p {
    text-align: center;
  }
}

@media (max-width: $tablet-width) {  
  .profile__tests-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    align-items: center;
    width: 100%;
  }
  
  .profile__test {
    min-width: 200px;
    padding: 25px;
    min-height: 220px;
  }
}

@media (max-width: $mobile-width) {
  .profile__tests-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  
  .profile__test {
    min-width: 100%;
    padding: 25px;
    min-height: 200px;
  }
}