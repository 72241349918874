@font-face {
  font-family: "Geometria";
  src: url("../fonts/Geometria-Light.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("../fonts/Geometria-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("../fonts/Geometria-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("../fonts/Geometria-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
