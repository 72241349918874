.fixed__bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 1rem;
  z-index: 2;
  padding: 24px 16px;
  padding-bottom: max(env(safe-area-inset-bottom), 1rem);
  display: none;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px 16px 0px 0px;

  span {
    font-size: 12px;
    line-height: 15px;
    color: #9b99a1;

    a {
      color: $main-color;
      font-weight: 500;

      @include hover-focus {
        padding-bottom: 1px;
        border-bottom: 1px solid $main-color;
      }

      &:active {
        transform: $trans;
      }
    }
  }
}

@supports(padding: max(0px)) {
  .fixed__bottom {
    padding-bottom: max(env(safe-area-inset-bottom), 1rem);
  }
}

@media (max-width: $mobile-width) {
  .fixed__bottom {
    display: flex;
  }
}