.result__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

.result__main {
  max-width: 847px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.result__container--profile {
  .result__main {
    max-width: 100%;
  }

  .result__lvl {
    max-width: 100%;
  }

  .result__diag li {
    gap: 20.8%;
  }
}

.result__top {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 24px;

  li {
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 16px;
    padding: 24px;
    min-height: 138px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: $text-color;
  }
}

.result__index {
  font-size: 40px;
  line-height: 50px;
  font-weight: 900;

  &.good {
    color: #00cf56;
  }

  &.normal {
    color: #ffa800;
  }

  &.bad {
    color: #e90000;
  }
}

.result__btn {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $main-color;
  padding: 0;
  padding-bottom: 4px;
  background-color: transparent;
  border: none;
  max-width: fit-content;
  border-bottom: 1px dashed $main-color;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $text-color;
    border-color: $text-color;
  }

  &:active {
    transform: scale(90%);
  }

  &:disabled {
    opacity: 90%;
    cursor: default;
  }
}

.result__type,
.result__cond {
  font-size: 16px;
  line-height: 20px;
  color: $text-color;
  font-weight: 700;
}

.result__mid {
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 16px;
  padding: 32px;
  min-height: 138px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 24px;
  width: 100%;
  min-height: 730px;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);
}

.result__lvl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 517px;
  margin-left: auto;
  width: 100%;
  grid-column: 2/-1;

  &-no {
    color: #c2cae4;
    grid-column: 1/2;
  }

  &-low {
    color: #00cf56;
    grid-column: 2/3;
  }

  &-mid {
    color: #ffa800;
    grid-column: 3/4;
  }

  &-high {
    color: #e90000;
    grid-column: 4/5;
  }

  li {
    position: relative;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    &::after {
      border: 1px dashed #dde1e6;
      content: '';
      height: 630px;
      left: 50%;
      position: absolute;
      top: 40px;
      width: 1px;
    }
  }
}

.result__diag {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  grid-column: 1/-1;

  li {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    gap: 20.5%;
    align-items: center;
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: $text-color;
    grid-column: 1/2;
    text-align: end;
  }
}

.result__line {
  min-height: 16px;
  background-color: transparent;
  width: 86%;
  display: flex;
}

.result__bar {
  min-width: 1%;
  border-radius: 0 10px 10px 0;
  background-color: $main-color;
  height: 16px;
  z-index: 1;
}

.result__container {
  .result__next {
    display: flex;
    justify-content: center;
    color: $color-default-white;
  }

  .result__share {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &:hover svg,
    &:focus svg {
      color: $color-default-white;
    }
  }
}

.results__overlay {
  align-items: center;
  background: hsla(0, 0%, 100%, 0.9);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  h3 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    max-width: 50%;
    width: 100%;
  }
}

.results__overlay-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;

  p {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 70%;
    width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .result__container {
    flex-direction: column;
  }

  .result__container--profile {
    .result__diag li {
      gap: 20.5%;
    }
  }

  .result__main {
    max-width: 100%;
  }

  .result__lvl {
    max-width: 100%;
  }

  .result__container {
    position: relative;

    .result__next {
      position: absolute;
      bottom: -60px;
      width: 100%;

      &::before {
        display: none;
      }
    }

    .result__share {
      bottom: -120px;
      width: 100%;
      position: absolute;
    }
  }
}

@media (max-width: $mobile-width) {
  .result__container--profile {
    .result__diag li {
      gap: 7%;
    }
  }

  .result__main {
    gap: 16px;
  }

  .result__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    gap: 16px;

    li {
      padding: 16px;
      min-height: 52px;
      gap: 6px;
      flex-direction: row;
      justify-content: space-between;

      &:first-child {
        flex-direction: column;
        justify-content: unset;
      }
    }
  }

  .result__index {
    font-size: 24px;
    line-height: 28px;
  }

  .result__type,
  .result__cond {
    font-size: 14px;
    line-height: 20px;
  }

  .result__mid {
    padding: 18px 16px;
    min-height: 138px;
    gap: 13px;
    min-height: 505px;
  }

  .result__lvl {
    li {
      font-size: 12px;
      line-height: 15px;

      &::after {
        height: 430px;
      }
    }
  }

  .result__diag {
    gap: 14px;

    li {
      grid-template-columns: 0.76fr 1fr;
      gap: 7%;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      text-align: start;
    }
  }

  .result__container {
    .result__next {
      position: static;
      display: none;
      justify-content: center;
      color: $color-default-white;
      font-size: 14px;
    }

    .result__share {
      position: static;
      display: none;
      justify-content: center;
      align-items: center;
      gap: 10px;

      span {
        color: $main-color;
      }

      &:hover,
      &:focus {
        svg {
          color: $color-default-white;
        }
        span {
          color: $color-default-white;
        }
      }
    }
  }

  .fixed__bottom {
    .result__share,
    .result__next {
      display: flex;
    }
  }

  .results__overlay h3 {
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
  }

  .results__overlay-container {
    p {
      max-width: 100%;
    }
  }
}
