.project__container {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 48px;
    color: $text-color;
  }

  p {
    color: $text-color;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 17px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: outside;
    margin-left: 15px;
  }
}


@media (max-width: $mobile-width) {
  .project__container {  
    h1 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
    }
  
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
}