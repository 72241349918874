.photo__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

.photo__main {
  max-width: 847px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.photo__screen {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.photo__descr {
  display: flex;
  gap: 24px;
}

.photo__example {
  min-width: 302px;
  min-height: 302px;
}

.photo__text {
  display: flex;
  flex-direction: column;

  p {
    font-weight: 500;
    color: $text-color-grey;
    margin-bottom: auto;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $text-color-second;
    margin-bottom: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: disc;
    padding-left: 18px;
    font-size: 16px;
    line-height: 20px;
    color: $text-color-second;
  }
}

.photo__buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.photo__feed {
  display: none;
}

.photo__btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 14px;
  max-width: 302px;
  width: 100%;
  justify-content: center;
}

.photo__hint {
  background-color: #f2f2f7;
  display: flex;
  gap: 24px;
  padding: 24px;
  align-items: flex-start;
  min-height: 274px;
}

.photo__upload form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.photo__qr {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  background-color: #dde1e6;
  border-radius: 8px;

  img {
    min-width: 160px;
    min-height: 160px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: $text-color-second;
    text-align: center;
  }
}

.photo__option {
  display: flex;
  flex-direction: column;
  gap: 14px;

  h2 {
    font-size: 24px;
    line-height: 30px;
    color: $text-color-second;
    font-weight: 900;
  }

  p {
    color: $text-color-grey;
  }
}

// второй экран
.photo__upload-image {
  align-items: center;
  background-color: #fafbfe;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 470px;
  justify-content: center;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;

  img {
    width: 100%;
    object-fit: contain;
    height: inherit;
  }

  span {
    color: $main-color;
    display: flex;
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    position: relative;

    &::before {
      background-image: url('../images/svg/icon-load-photo.svg');
      background-repeat: no-repeat;
      content: '';
      height: 40px;
      left: -63px;
      position: absolute;
      top: -8px;
      width: 40px;
    }
  }
}

.photo__upload-buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.photo__upload-button input {
  display: none;
}

.photo__upload-error {
  color: $color-error;
  font-size: 18px;
  line-height: 22px;
}

.photo__analysis {
  position: relative;
  display: flex;
  gap: 24px;
}

.photo__analysis-image {
  position: relative;
  width: fit-content;
  min-width: fit-content;
  overflow: hidden;
  border-radius: 16px;

  img {
    display: block;
    max-width: 304px;
    min-height: 401px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.photo__analysis-line {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  transition: width 0.5s ease-out;
  display: block;
  height: 44px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(208, 20, 129, 0.6) 48%,
    #d01481 50%,
    rgba(208, 20, 129, 0.6) 52%,
    transparent 100%
  );
}

.photo__analysis-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.photo__analysis-progress {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 16px;
}

.photo__abalysis-bar {
  position: absolute;
  top: 0;
  border-radius: 16px;
  height: 100%;
  background-color: $main-color;
}

.photo__analysis-status {
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: $text-color-second;
  }

  span {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: $text-color-second;
  }
}

.invalid__photo {
  display: flex;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
}

.invalid__photo-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 370px;
  width: 100%;
  min-height: 414px;
  background-color: #fafbfe;
  border-radius: 16px;

  & svg {
    width: 50px;
    height: 50px;
    color: $main-color;
  }
}

.invalid__photo-descr {
  padding: 46px 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & h2 {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    color: $main-color;
    font-weight: 900;
  }

  & span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #333333;
  }

  & ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 20px;
    list-style-type: disc;
  }

  & li {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
}

a.photo__skip {
  margin-left: auto;
  margin-right: 15px;
  background-color: transparent;
  color: #333333;
  justify-content: flex-start;
  width: fit-content;
}

@media (max-width: $tablet-width) {
  .photo__container {
    flex-direction: column;
  }

  .photo__main {
    max-width: 100%;
  }

  .photo__buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .photo__feed {
    display: block;
    max-width: 302px;
    font-size: 14px;
    width: 100%;
  }

  .photo__hint {
    display: none;
  }

  .photo__btn svg {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: $mobile-width) {
  .photo__descr {
    flex-direction: column;
  }

  .photo__example img {
    width: 100%;
  }

  .photo__text p {
    margin-bottom: 16px;
  }

  .photo__buttons {
    display: none;
  }

  .photo__btn,
  .photo__feed {
    max-width: 100%;
  }

  .photo__upload-buttons {
    display: none;
  }

  .photo__upload-image span {
    font-size: 20px;
    line-height: 24px;

    &::before {
      width: 30px;
      height: 30px;
      background-size: contain;
      top: -4px;
      left: -45px;
    }
  }

  .photo__analysis-image img {
    max-width: 100%;
    min-height: auto;
  }

  .photo__analysis {
    flex-direction: column;
    gap: 16px;
  }

  .photo-analysis-line {
    content: '';
    position: absolute;
    left: 0;
    z-index: 5;
    display: block;
    height: 35px;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(208, 20, 129, 0.6) 48%,
      #d01481 50%,
      rgba(208, 20, 129, 0.6) 52%,
      transparent 100%
    );
    animation: animLoader 10s linear infinite;
  }

  @keyframes animLoader {
    0% {
      top: -100%;
    }
    100% {
      top: 100%;
    }
  }

  .invalid__photo {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
    position: relative;
  }

  .invalid__photo-image {
    position: absolute;
    max-width: 50px;
    min-height: 50px;
    background-color: transparent;
    border-radius: 0;
  }

  .invalid__photo-descr {
    padding: 0;

    & h2 {
      display: flex;
      align-items: center;
      padding-left: 66px;
      font-size: 16px;
      line-height: 20px;
      min-height: 50px;
    }
  }
}
