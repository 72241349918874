.about {
  background-image: url('../images/notwebp/about-bg.png');
  background-size: cover;
  margin-bottom: 56px;

  @include retina {
    background-image: url('../images/notwebp/about-bg@2x.png');
  }
}

.about__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about__text {
  display: flex;
  flex-direction: column;
  padding: 60px 70px;
  border-radius: 54px 0px;
  background-color: #fdf6fa;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  min-height: fit-content;
  max-width: 736px;
  width: 100%;

  h2 {
    font-size: 35px;
    line-height: 43px;
    font-weight: 700;
    color: $main-color;
    margin-bottom: 48px;
    text-transform: uppercase;
  }

  p {
    font-size: 31px;
    line-height: 38px;
    font-weight: 400;
    color: $main-color;
    max-width: 90%;
    margin-bottom: 30px;
  }

  b {
    font-weight: 700;
  }

  a {
    max-width: 455px;
    margin-bottom: 15px;
  }

  span {
    font-size: 17px;
    line-height: 21px;
    max-width: 90%;
    color: #9b99a1;

    a {
      color: $main-color;
      font-weight: 500;

      @include hover-focus {
        padding-bottom: 1px;
        border-bottom: 1px solid $main-color;
      }

      &:active {
        transform: $trans;
      }
    }
  }
}

.about__images {
  margin-left: 25px;
  flex: none;

  ul {
    display: flex;
    align-items: center;
  }

  li {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.about__picture-first {
  max-width: 220px;
  min-height: 220px;
  border-radius: 36px;
  border: 4px solid #f6d0e6;
  background: radial-gradient(82.5% 82.5% at 50% 50%, #fff 0%, #f6d0e6 100%);
  transform: translateY(-22px);

  img {
    border-radius: 36px;
  }
}

.about__picture-second {
  max-width: 140px;
  min-height: 140px;
  border-radius: 28px;
  border: 3px solid #f49ac1;
  background: var(---White, #fff);
  transform: translateY(40px);
  margin-left: 35px;

  img {
    border-radius: 28px;
  }
}

.about__picture-third {
  max-width: 160px;
  min-height: 160px;
  border-radius: 26px;
  border: 3px solid $main-color;
  transform: translateY(-140px);
  margin-left: -40px;

  img {
    border-radius: 26px;
  }
}

@media (max-width: $tablet-width) {
  .about__text {
    padding: 32px 25px;
    border-radius: 27px 0px;
    box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
    min-height: 229px;
    max-width: 100%;
    width: 100%;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background-image: url('../images/notwebp/about-mob.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 130px;
      height: 171px;
      bottom: -26px;
      right: 20px;

      @include retina {
        background-image: url('../images/notwebp/about-mob@2x.png');
      }
    }

    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 25px;
    }

    p {
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }

  .about__images {
    display: none;
  }
}

@media (max-width: $mobile-width) {
  .about {
    background-image: none;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      min-height: 100%;
      background-image: url("../images/notwebp/about-bg.png");
      background-size: cover;
      bottom: -75px;
      left: 0;
      z-index: 0;

      @include retina {
        background-image: url("../images/notwebp/about-bg@2x.png");
      }
    }
  }

  .about__text {
    z-index: 1;

    &::after {
      width: 120px;
      height: 176px;
      bottom: -48px;
      right: -5px;
    }

    h2 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 32px;
    }

    p {
      font-size: 17px;
      line-height: 21px;
      font-weight: 500;
      margin-bottom: 0;
    }

    span {
      // ссылка Bottom sheet
      display: none;
    }

    .about__test {
      // ссылка Bottom sheet
      display: none;
    }

    .about__images {
      margin-left: 25px;
      flex: none;

      ul {
        display: flex;
        align-items: center;
      }

      li {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    .about__picture-first {
      max-width: 220px;
      min-height: 220px;
      border-radius: 36px;
      border: 4px solid #f6d0e6;
      background: radial-gradient(
        82.5% 82.5% at 50% 50%,
        #fff 0%,
        #f6d0e6 100%
      );
      transform: translateY(-22px);

      img {
        border-radius: 36px;
      }
    }

    .about__picture-second {
      max-width: 140px;
      min-height: 140px;
      border-radius: 28px;
      border: 3px solid #f49ac1;
      background: var(---White, #fff);
      transform: translateY(40px);
      margin-left: 35px;

      img {
        border-radius: 28px;
      }
    }

    .about__picture-third {
      max-width: 160px;
      min-height: 160px;
      border-radius: 26px;
      border: 3px solid $main-color;
      transform: translateY(-140px);
      margin-left: -40px;

      img {
        border-radius: 26px;
      }
    }
  }
}
