#error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  gap: 20px;
  line-height: 30px;
  text-align: center;

  button {
    min-width: 210px;
  }
}

#error-overlay .error-overlay-message {
  font-size: 24px;
}
