.loading__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  display: none;
  padding: 20px;

  p {
    font-size: 24px;
    line-height: 30px;
    color: $main-color;
    text-align: center;
    font-weight: 700;
  }
}

.loading__overlay.visible {
  display: flex;
}

.loading__spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $main-color;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
