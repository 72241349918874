.rules__container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 900;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: decimal;
    padding-left: 15px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 15px;
  }
}

@media (max-width: $mobile-width) {
  .rules__container {
    gap: 20px;
  
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  
    ol {
      gap: 10px;
      padding-left: 15px;
    }
  
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}