.sidemenu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 410px;
  width: 100%;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
    color: $main-color;
  }

  ul {
    border-left: 1px solid #dde1e6;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    align-items: center;
  }

  a {
    min-height: 32px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    color: $text-color-grey;

    @include hover-focus {
      color: $main-color;
    }

    &.is-done {
      border-left: 1px solid #d01481;
    }
  }
}

.sidemenu__current {
  min-height: 32px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  color: $main-color;
  border-left: 1px solid $main-color;
}

@media (max-width: $tablet-width) {
  .sidemenu {
    max-width: 100%;
    gap: 14px;

    h2 {
      font-size: 28px;
      line-height: 36px;
    }
  
    ul {
      border-left: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
      margin: 0 -15px;
      max-width: fit-content;
      overflow-x: auto;
      padding: 12px 15px;
    }
  
    a {
      padding-left: 0;
      white-space: nowrap;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: -21px;
        transform: translate(0, -50%);
        background-image: url("../images/svg/close-link.svg");
        background-repeat: no-repeat;
        height: 14px;
        width: 14px;
      }

      &.is-done {
        border: none;

        &::before {
          display: none;
        }
      }
    }

    button {
      display: none;
    }
  }
  
  .sidemenu__current {
    white-space: nowrap;
    padding-left: 0;
    color: $main-color;
    border-left: none;
    margin-left: 0;
  }
}