.header {
  margin-bottom: 25px;
}

.header__container {
  padding: 22px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  @include hover-focus {
    transform: $trans;
  }
}

.header__nav {
  max-width: 350px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
}

.header__lang {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 19px;
  border: none;
  cursor: pointer;
  min-height: 57px;
  border-radius: 8px;
  color: $main-color;
  padding: 17px 30px;

  span {
    font-family: $ff-geo;
    font-size: 18px;
    line-height: 18px;
    color: $main-color;
  }

  @include hover-focus {
    background-color: $main-color;
    color: $color-default-white;

    span {
      color: $color-default-white;
    }
  }

  &:active {
    transform: $trans;
  }
}

.header__link:not(span) {
  color: $text-color;

  @include hover-focus {
    color: $main-color;
  }
}

.header__link.is-active {
  color: $main-color;
  padding-bottom: 5px;
  color: $main-color;
  border-bottom: 3px solid $main-color;
}

.header__buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.header__auth.is-closed {
  display: none;
}

.header__burger {
  border: none;
  background-color: transparent;
  color: #9b99a1;
  cursor: pointer;

  @include hover-focus {
    color: $main-color;
  }

  &:not(:disabled):active {
    transform: $trans;
  }

  &:disabled {
    opacity: 10%;
  }

  & span {
    font-size: $fs-default;
  }
}

.header__burger.is-closed svg:last-child {
  transform: rotate(180deg);
}

.header__burger--desk {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header__burger--mob {
  display: none;
}

.header__menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 332px;
  min-height: fit-content;
  padding: 24px;
  gap: 10px;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  top: 81px;
  right: 90px;
  background-color: $color-default-white;
  z-index: 2;

  & ul {
    display: flex;
    flex-direction: column;
  }
}

.header__menu-link {
  display: flex;
  align-items: center;
  min-height: 24px;
  width: 100%;
  gap: 16px;
  padding: 9px 0;
  color: $text-color;
  border: none;
  background-color: transparent;

  @include hover-focus {
    color: $main-color;
  }

  &:active {
    transform: $trans;
  }
}

.header__menu-button {
  display: flex;
  padding: 15px 18px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #f2f2f7;
  background-color: #f2f2f7;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: $text-color;
  font-family: $ff-geo;

  @include hover-focus {
    color: $color-default-white;
    background-color: $main-color;
  }

  &:active {
    transform: $trans;
  }
}

.header__menu-button.js-modal {
  display: none;
}

.header__menu.is-closed {
  display: none;
}

.header__login.hidden {
  display: none;
}

@media (max-width: $tablet-width) {
  .header {
    margin-bottom: 15px;
  }

  .header__container {
    padding: 15px 0;
  }

  .header__logo {
    max-width: 200px;
  }

  .header__lang {
    padding: 10px 15px;
    min-height: 40px;
    font-size: 16px;
    line-height: 18px;
  }

  .header__nav {
    max-width: 270px;
  }

  .header__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header__burger {
    & span {
      font-size: 16px;
    }

    & svg:first-child {
      width: 24px;
      height: 24px;
    }
  }

  .header__menu {
    right: 15px;
  }
}

@media (max-width: $mobile-width) {
  .header {
    margin-bottom: 24px;
  }

  .header__container {
    padding: 13px 0;
  }

  .header__logo {
    max-width: 200px;
  }

  .header__lang {
    display: none;
  }

  .header__nav {
    display: none;
  }

  .header__burger--desk {
    display: none;
  }

  .header__burger--mob {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    z-index: 5;

    span {
      width: 32px;
      height: 2px;
      background-color: $main-color;
      transition: all 0.3s ease;
    }

    span:first-child {
      padding-top: 2.5px;
    }

    span:last-child {
      padding-bottom: 2px;
    }
  }

  .header__burger .header__burger--mob{
    @include hover-focus {
      animation: bounce 3s ease-in-out infinite;
      animation-delay: 0.5s;
    }
  }

  .header__burger:not(.is-closed) span:first-child {
    padding-top: 0;
    transform: rotate(45deg) translate(4px, 1px);
    height: 2px;
  }

  .header__burger:not(.is-closed) span:nth-child(2) {
    opacity: 0;
  }

  .header__burger:not(.is-closed) span:last-child {
    padding-bottom: 0;
    transform: rotate(-45deg) translate(8px, -6px);
    height: 2px;
  }

  .header__menu.is-closed {
    top: -100%;
  }

  .header__menu {
    max-width: 100%;
    right: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 60px 24px;
    z-index: 3;
    justify-content: center;
    gap: 30px;
    transition: top 0.5s ease;

    ul {
      gap: 10px;
      margin: 0 auto;
    }
  }

  .header__menu-button.js-modal {
    display: flex;
  }
}
