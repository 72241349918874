.cookies__container {
  max-width: 950px;
  min-height: 180px;
  padding: 48px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  background-color: $color-default-white;
  border-radius: 16px;
  box-shadow: 0px 0px 22px 0px rgba(163, 163, 163, 1.10);
  align-items: center;
  position: fixed;
  bottom: 60px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.cookies__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 86%;

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: $color-default-black;
  }

  p {
    font-size: 18px;
    line-height: 20px;
  }
}

.cookies__btn {
  max-width: 105px;
  min-height: 42px;
  padding: 12px 18px;
  font-size: 18px;
  line-height: 18px;
}

@media (max-width: $tablet-width) {
  .cookies__container {
    max-width: 80%;
  }
}

@media (max-width: $tablet-width) {
  .cookies__container {
    display: none;
  }
}