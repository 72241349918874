.test__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

a.test__skip {
  margin-left: auto;
  margin-right: 15px;
  background-color: transparent;
  color: #333333;
  justify-content: flex-start;
  width: fit-content;
}

.test__main {
  max-width: 847px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.test__content {
  display: flex;
  flex-direction: column;
}

.test__question {
  display: none;
  flex-direction: column;
  gap: 34px;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: $text-color;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  li {
    max-width: 48%;
    width: 100%;

    button,
    label {
      display: flex;
      background-color: transparent;
      border: 1px solid #dde1e6;
      font-size: 18px;
      line-height: 22px;
      color: $text-color;
      border-radius: 16px;
      min-height: 56px;
      width: 100%;
      padding: 0 24px;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background-color: $main-color;
        color: $color-default-white;
      }

      &:not(:disabled):active {
        transform: scale(90%);
      }

      &:disabled {
        opacity: 90%;
      }

      &.is-chosen {
        background-color: $main-color;
        color: $color-default-white;

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background-color: transparent;
          color: $main-color;
        }

        &:not(:disabled):active {
          transform: scale(90%);
        }
      }
    }
  }

  & input:checked + label {
    background-color: $main-color;
    color: $color-default-white;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $main-color;
    }
  }

  &.is-active {
    display: flex;
  }
}

.test__question--init {
  display: flex;
}

.test__feed {
  display: none;
  margin-top: 80px;
}

.test__button-next {
  max-width: 50%;
}

.test__tooltip {
  border-radius: 16px;
  padding: 16px;
  background-color: #f2f2f7;
  padding-left: 72px;
  position: relative;
  font-size: 18px;
  line-height: 22px;
  color: #9b99a1;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    background-color: $main-color;
    background-image: url('../images/svg/test-tooltip.svg');
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
  }
}

.test__age {
  min-height: 64px;
  border: 1px solid #dde1e6;
  padding: 0 24px;
  border-radius: 10px;
  background-color: #f2f2f7;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  min-width: 424px;

  &::placeholder {
    font-size: 18px;
    line-height: 26px;
    color: #9b99a1;
  }
}

.test__button-back {
  max-width: 30%;
}

.test__send {
  max-width: 50%;
}

@media (max-width: $tablet-width) {
  .test__container {
    flex-direction: column;
  }

  .test__main {
    max-width: 100%;
  }

  .test__question {
    gap: 20px;

    h2 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .test__feed {
    display: block;
  }

  .test__age {
    min-width: 379px;
  }
}

@media (max-width: $mobile-width) {
  .test__question {
    gap: 24px;

    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    li {
      max-width: 100%;
    }
  }

  .test__feed {
    display: block;
  }

  .test__age {
    min-width: 100%;
  }

  .test__button-back {
    max-width: 300px;
  }

  .test__send {
    max-width: 100%;
  }
}
