.descr__container {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  min-height: 253px;
}

.descr__text {
  font-size: 24px;
  line-height: 30px;
  color: #9b99a1;
  margin-bottom: 17px;
  text-align: center;
}

.descr__title {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: #18191e;
  margin-bottom: 48px;
  text-align: center;
}

.descr__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  gap: 24px;

  li {
    border: 1px solid #dde1e6;
    border-radius: 16px;
    max-width: 538px;
    width: 100%;
    min-height: 253px;
    padding: 24px;
  }

  p {
    font-size: 22px;
    line-height: 27px;
    color: #18191e;
    text-align: left;
  }
}

.descr__step {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 24px;

  h3 {
    position: absolute;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: #18191e;
    left: 40px;
    top: 26px;
  }

  span {
    color: $main-color;
  }
}

.descr__btn {
  max-width: 280px;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: $tablet-width) {
  .descr__container {
    max-width: 100%;
    margin: 0;
  }

  .descr__text {
    font-size: 20px;
    line-height: 22px;
  }

  .descr__title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 28px;
  }

  .descr__list {
    margin-bottom: 28px;
    gap: 18px;
    align-items: stretch;

    li {
      max-width: 49%;
      padding: 20px;
      min-height: 210px;
    }

    p {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .descr__step {
    margin-bottom: 20px;

    svg {
      width: 80px;
      height: 80px;
    }

    h3 {
      position: absolute;
      font-size: 24px;
      line-height: 28px;
      max-width: 190px;
      top: 23px;
    }
  }

  .descr__btn {
    max-width: 280px;
    margin: 0 auto;
  }
}

@media (max-width: $mobile-width) {
  .descr__text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    text-align: left;
  }

  .descr__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .descr__list {
    margin-bottom: 0;
    gap: 24px;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;

    li {
      max-width: 100%;
      padding: 16px;
      min-height: 160px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .descr__step {
    margin-bottom: 17px;

    svg {
      width: 50px;
      height: 50px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      max-width: 160px;
      top: 14px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .descr__btn {
    display: none;
  }
}
