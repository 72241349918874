.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  max-width: 50%;
  padding: 40px;
  width: 90%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  gap: 40px;
}

.modal-text {
  font-size: 20px;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons .btn {
  flex: 1;
  margin: 0 5px;
}

@media (max-width: $mobile-width) {
  .modal-content {
    max-width: 100%;
    padding: 20px;
    width: 90%;
    gap: 20px;
  }

  .modal-text {
    font-size: 16px;
    margin-bottom: 18px;
  }
  
}
